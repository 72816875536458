angular.module('app.resources', ['ngResource']).factory('APIProducts', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/products/:product_id.json",
  {
      _products: true,
      product_id: '@product_id'
    });
  }
]).factory('APIExhibitors', [
  '$resource',
  function($resource) {
    return $resource("api3.php");
  }
]).factory('APIArticles', [
  '$resource',
  function($resource) {
    return $resource("core/read_data.php");
  }
]).factory('APIData', [
  '$resource',
  function($resource) {
    return $resource("core/data/data-:slug.json",
  {
      slug: '@slug'
    });
  }
]).factory('APICheckin', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/tickets/:shortcode/:action",
  {
      action: '@action'
    });
  }
]);
